import React from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  const handleRequestAccess = () => navigate('/signup');
  const handleLogin = () => navigate('/login');

  return (
    <div className="App">
      <div className="halo"></div>
      <img src="/TaskerForce_logo_fav.png" alt="TaskerForce Logo" className="logo" />
      <h1 className="welcome">Welcome to TaskerForce!</h1>
      <p className="slogan">A dedicated AI-Nonner, built for Aircraft Maintainers.</p>
      <div className="buttons">
        <button onClick={handleRequestAccess}>Request Access</button>
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
}

export default Home;
