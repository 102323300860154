import React, { useState, useEffect } from 'react';

function Chatbot() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const isAdmin = localStorage.getItem('is_admin') === 'true';

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const userMessage = { role: 'user', content: input };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/chatbot`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token') || ''}`,
        },
        body: JSON.stringify({ message: input }),
      });

      if (response.ok) {
        const data = await response.json();
        const botMessage = { role: 'bot', content: data.reply };
        setMessages((prev) => [...prev, botMessage]);
      } else {
        const error = await response.json();
        console.error('Backend error:', error);
        setMessages((prev) => [...prev, { role: 'bot', content: 'Sorry, something went wrong on the server.' }]);
      }
    } catch (err) {
      console.error('Network error:', err);
      setMessages((prev) => [...prev, { role: 'bot', content: 'Unable to connect. Please try again.' }]);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') handleSendMessage();
  };

  const handleClearChat = () => {
    setMessages([]);
  };

  const handleAdminAction = () => {
    alert("Admin action triggered!");
  };

  useEffect(() => {
    const chatContainer = document.getElementById('chat-container');
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [messages]);

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <h1>Chatbot</h1>
      {isAdmin && (
        <div style={{ marginBottom: '10px' }}>
          <h3>Admin Controls</h3>
          <button onClick={handleClearChat} style={{ marginRight: '10px' }}>Clear Chat</button>
          <button onClick={handleAdminAction}>Admin Test Action</button>
        </div>
      )}
      <div
        id="chat-container"
        style={{
          border: '1px solid #ccc',
          padding: '10px',
          height: '400px',
          overflowY: 'scroll',
          marginBottom: '10px',
        }}
      >
        {messages.map((message, index) => (
          <div key={index} style={{ margin: '10px 0' }}>
            <strong style={{ color: message.role === 'user' ? 'blue' : 'green' }}>
              {message.role === 'user' ? 'You' : 'Bot'}:
            </strong>{' '}
            {message.content}
          </div>
        ))}
        {loading && <p style={{ fontStyle: 'italic', color: 'gray' }}>Bot is typing...</p>}
      </div>
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type your message..."
        style={{ width: 'calc(100% - 50px)', padding: '10px' }}
        aria-label="Message input"
      />
      <button
        onClick={handleSendMessage}
        style={{ width: '50px', height: '40px', marginLeft: '10px' }}
        disabled={loading}
        aria-label="Send message"
      >
        Send
      </button>
    </div>
  );
}

export default Chatbot;
