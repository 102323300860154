import React, { useEffect, useState } from 'react';
import './admindashboard.css';

function AdminDashboard() {
  const [signupRequests, setSignupRequests] = useState([]);
  const [approvedUsers, setApprovedUsers] = useState([]);
  const [error, setError] = useState('');
  const [loadingRequests, setLoadingRequests] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchSignupRequests();
    fetchApprovedUsers();
  }, []);

  const fetchSignupRequests = async () => {
    setLoadingRequests(true);
    setError('');
    if (!token) {
      setError('You must be logged in as an admin to view this page.');
      setLoadingRequests(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/signup-requests`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setSignupRequests(data);
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Failed to fetch signup requests.');
      }
    } catch (err) {
      console.error(err);
      setError('Failed to connect to the server.');
    } finally {
      setLoadingRequests(false);
    }
  };

  const fetchApprovedUsers = async () => {
    setLoadingUsers(true);
    setError('');
    if (!token) {
      setError('You must be logged in as an admin to view this page.');
      setLoadingUsers(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setApprovedUsers(data);
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Failed to fetch approved users.');
      }
    } catch (err) {
      console.error(err);
      setError('Failed to connect to the server.');
    } finally {
      setLoadingUsers(false);
    }
  };

  const handleApprove = async (email) => {
    if (!token) {
      setError('You must be logged in as an admin to perform this action.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/approve`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        alert('User approved successfully!');
        setSignupRequests(signupRequests.filter((req) => req.email !== email));
        fetchApprovedUsers();
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Failed to approve user.');
      }
    } catch (err) {
      console.error(err);
      setError('Failed to connect to the server.');
    }
  };

  const handleDeny = async (email) => {
    if (!token) {
      setError('You must be logged in as an admin to perform this action.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/deny`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        alert('User denied successfully!');
        setSignupRequests(signupRequests.filter((req) => req.email !== email));
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Failed to deny user.');
      }
    } catch (err) {
      console.error(err);
      setError('Failed to connect to the server.');
    }
  };

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>
      {error && <p className="error-message">{error}</p>}

      <h2>Pending Signup Requests</h2>
      {loadingRequests && <p>Loading requests...</p>}
      {!loadingRequests && signupRequests.length === 0 && !error && (
        <p>No pending signup requests.</p>
      )}
      {!loadingRequests && signupRequests.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {signupRequests.map((request) => (
              <tr key={request.email}>
                <td>{request.firstName} {request.lastName}</td>
                <td>{request.email}</td>
                <td>
                  <button onClick={() => handleApprove(request.email)}>Approve</button>
                  <button onClick={() => handleDeny(request.email)}>Deny</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <h2>Approved Users</h2>
      {loadingUsers && <p>Loading users...</p>}
      {!loadingUsers && approvedUsers.length === 0 && !error && (
        <p>No approved users found.</p>
      )}
      {!loadingUsers && approvedUsers.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Admin</th>
              <th>Disabled</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {approvedUsers.map((user) => (
              <tr key={user.email}>
                <td>{user.firstName} {user.lastName}</td>
                <td>{user.email}</td>
                <td>{user.is_admin ? "Yes" : "No"}</td>
                <td>{user.is_disabled ? "Yes" : "No"}</td>
                <td>
                  {/* Add Reset Password, Disable, Enable, Remove functions if needed */}
                  {/* Example: <button>Reset Password</button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default AdminDashboard;
