import React, { useEffect, useState } from 'react';
import './app.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import Signup from './signup';
import Login from './login';
import AdminDashboard from './admindashboard';
import Chatbot from './chatbot';
import Home from './home';

function App() {
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingAdminStatus, setLoadingAdminStatus] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    console.log('[App] Stored token on load:', storedToken);
    setToken(storedToken);

    if (storedToken) {
      const fetchAdminStatus = async () => {
        console.log('[App] Attempting to fetch admin status with token:', storedToken);
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/admin-status`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${storedToken}`,
            },
          });

          console.log('[App] /auth/admin-status response status:', response.status);
          if (response.ok) {
            const data = await response.json();
            console.log('[App] /auth/admin-status data:', data);
            setIsAdmin(data.is_admin);
            localStorage.setItem('is_admin', data.is_admin ? 'true' : 'false');
          } else {
            // If it's not okay, it might be 401 or something else
            console.warn('[App] Failed to fetch admin status. Status:', response.status);
            // If token is invalid or something is off, we can decide what to do:
            // For example, if 401, maybe clear token?
            if (response.status === 401) {
              console.warn('[App] 401 Unauthorized. Clearing local token and forcing re-login.');
              localStorage.removeItem('token');
              localStorage.setItem('is_admin', 'false');
              setIsAdmin(false);
              setToken(null);
              // Not redirecting automatically; user might click admin-dashboard again.
            }
          }
        } catch (error) {
          console.error('Error checking admin status:', error);
        } finally {
          setLoadingAdminStatus(false);
        }
      };

      fetchAdminStatus();
    } else {
      console.log('[App] No stored token, setting isAdmin to false.');
      setIsAdmin(false);
      localStorage.setItem('is_admin', 'false');
      setLoadingAdminStatus(false);
    }
  }, []);

  // Additional console logging for debugging route conditions
  console.log('[App] Rendering routes. isAdmin:', isAdmin, 'token:', token, 'loadingAdminStatus:', loadingAdminStatus);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/login" element={<Login />} />

      <Route 
        path="/admin-dashboard"
        element={
          !loadingAdminStatus && token && isAdmin ? <AdminDashboard /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/chatbot"
        element={
          token ? <Chatbot /> : <Navigate to="/login" />
        }
      />

      <Route path="*" element={<div>404 - Page Not Found</div>} />
    </Routes>
  );
}

export default App;
