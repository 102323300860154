import React, { useState } from 'react';
import './signup.css'; // reusing signup.css for styling

function Login() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.token) {
          localStorage.setItem('token', data.token);
        }
        localStorage.setItem('is_admin', data.is_admin ? 'true' : 'false');

        if (data.is_admin) {
          alert('Welcome, Admin!');
          window.location.href = '/admin-dashboard';
        } else {
          alert('Login successful!');
          window.location.href = '/chatbot';
        }
      } else {
        const errorData = await response.json();
        setError(errorData.detail || 'Invalid email or password.');
        console.error('Login failed:', errorData);
      }
    } catch (err) {
      setError('Failed to connect to the server. Please try again later.');
      console.error('Connection error:', err);
    } finally {
      setSubmitted(false);
    }
  };

  return (
    <div className="signup-container">
      <div className="terminal scanlines">
        <h1>Login</h1>
        <p>Enter your credentials to access TaskerForce.</p>
        <form className="signup-form" onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

          <label htmlFor="password">Password</label>
          <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} required />

          <button type="submit" className="btn" disabled={submitted}>
            {submitted ? 'Logging in...' : 'Login'}
          </button>
        </form>
        {error && <p className="error-message">{error}</p>}
        <p className="back-to-home">
          <a href="/">← Back to Home</a>
        </p>
      </div>
    </div>
  );
}

export default Login;
